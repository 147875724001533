
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import BriefModel from '../../../../models/Briefs/BriefModel'
import DatePicker from './DatePicker.vue'
import _ from 'lodash'
import InstruLogCard from '@/components/IoHealthCheck/InstruLogCard.vue'

@Component({
  components: {
    InstruLogCard,
    DatePicker
  }
})
export default class StatusLogsComponent extends Vue {
  @Prop({ required: true }) externalId: number
  @Prop({ required: true }) dsp: string
  @Prop({ required: true }) brief: BriefModel
  @Prop({ required: false, default: false }) openActivityLogs: boolean

  statusLogs: Array<{date: Date, text: string}> = []
  filteredStatusLogs: Array<{date: Date, text: string}> = []

  contactMailCopilot: string = null

  statusLogsLoading: boolean = false
  statusLogsOrder: string = 'desc'

  statusLogsSelectedDate: any = null

  dialogInstruLog: boolean = false

  mounted () {
    if (this.openActivityLogs) {
      this.openInstruLogs()
    }
  }

  async queryStatusLogs () {
    if ((this.statusLogs == null || this.statusLogs.length === 0) && this.externalId != null) {
      this.statusLogsLoading = true
      await this.getStatusLogs()
      this.statusLogsLoading = false
    }
  }

  async getStatusLogs () {
    let result = null
    result = await this.$apiCaller.getStatusLogs(this.externalId)
    if (this.$apiCaller.isResponseError(result, true)) {
      console.warn('Error when loading status logs.')
      this.statusLogsLoading = false
    } else {
      this.statusLogs = this.statusLogsSorter(result.data)
      this.filteredStatusLogs = this.statusLogs
      await this.getComplementaryLogData()
    }
  }

  async getComplementaryLogData () {
    if (this.externalId != null && this.brief && _.isObject(this.brief)) {
      // let sortedBrief = result.data.sort((a: any, b: any) => (b.insertion_date > a.insertion_date) ? 1 : -1)[0]
      if ('swid' in this.brief && 'contactMailCopilot' in this.brief && this.brief.contactMailCopilot != null) {
        this.contactMailCopilot = this.brief.contactMailCopilot
      }
    }
  }

  statusLogsSorter (data: StatusLog[]): Array<{date: Date, text: string}> {
    return data.sort((a: StatusLog, b: StatusLog) => {
      return this.$commonUtils.sortDate(a.update_date, b.update_date)
    }).map((item: StatusLog) => {
      return {
        date: new Date(item.update_date),
        text: this.logStatusSentences(item)
      }
    })
  }

  logStatusSentences (log: any): string {
    if (log.status === 'PENDING') {
      return `Added by ${log.user_mail}`
    } else if (log.status === 'COMPUTING') {
      return `Validated by ${log.user_mail}`
    } else if (log.status === 'TO_VALIDATE') {
      return `Sent for validation`
    } else if (log.status === 'LIVE') {
      return `Optimized by Scibids`
    } else if (log.status === 'ON_HOLD') {
      return `Optimization paused by Scibids due to inactivity. It will restart as soon as the IO spends again`
    } else if (log.status === 'CANCEL') {
      return `Cancelled by ${log.user_mail}`
    }
    return log.status
  }

  displayLogDate (rawDate: number) {
    const date = new Date(rawDate)
    return [
      date.getFullYear() + '-',
      (date.getMonth() + 1 > 9 ? '' : '0') + (date.getMonth() + 1) + '-',
      (date.getDate() > 9 ? '' : '0') + date.getDate() + ' ',
      (date.getHours() > 9 ? '' : '0') + date.getHours() + ':',
      (date.getMinutes() > 9 ? '' : '0') + date.getMinutes() + ':',
      (date.getSeconds() > 9 ? '' : '0') + date.getSeconds()
    ].join('')
  }

  changeStatusLogsOrder (order: string) {
    if (this.statusLogsOrder !== order) {
      this.statusLogs.reverse()
    }
    this.statusLogsOrder = order
  }

  statusLogsFilteredByDate () {
    if (this.statusLogsSelectedDate == null || this.statusLogsSelectedDate === '') {
      this.filteredStatusLogs = this.statusLogs
      return
    }
    const logs = []
    for (let item of this.statusLogs) {
      let yyyymmdd = [
        item.date.getFullYear() + '-',
        (item.date.getMonth() + 1 > 9 ? '' : '0') + (item.date.getMonth() + 1) + '-',
        (item.date.getDate() > 9 ? '' : '0') + item.date.getDate()
      ].join('')
      if (yyyymmdd === this.statusLogsSelectedDate) { logs.push(item) }
    }
    this.filteredStatusLogs = logs
  }

  openInstruLogs () {
    this.dialogInstruLog = true
  }

  closeInstruLogs () {
    this.dialogInstruLog = false
  }

  @Watch('statusLogsSelectedDate')
  onStatusLogsSelectedDateChange () {
    this.statusLogsFilteredByDate()
  }
}

